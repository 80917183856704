import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Styled from "styled-components"
import { Helmet } from "react-helmet"

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 86px;
  p {
    margin-bottom: 0;
  }
  
  @media all and (min-width: 992px) {
    p {
      white-space: pre-wrap;
    }
    padding: 50px 75px;
  }
}
`

const Highlighted = Styled.p`
  color: ${props => props.theme.headerText};
  margin-bottom: 13px !important;
`

const title = "Visible Brand Actions™"

const VisibleBrandActions = () => {
  return (
    <Layout activeMenu={title}>
      <SEO title={title} />
      <Helmet>
        <title>Visible Brand Actions™</title>
        <meta
          name="description"
          content="Sambal Lab produce Visible Actions that your brand can do to trigger..."
        />
      </Helmet>
      <Container>
        <p
          dangerouslySetInnerHTML={{
            __html:
              "In a world where consumer attention shifts by the minute,\nhow do you earn loyalty?",
          }}
        />
        <p>
          <br />
          You break marketing tradition.
          <br />
          <br />
          Sambal Lab produce Visible Actions that your brand can do to trigger
          emotions, engagement, even social movements. Present stories that have
          not been told before, and the media will publicise. Excite influencers
          with a new toy and they’ll share it—for free. Throw a party in another
          dimension and millennials will connect with you, and each
          other—expanding your fanbase. The idea is to disrupt the consumer
          landscape with unconventional experiences. The bottomline,
          significantly more ROI than the conventional.
        </p>
        <Highlighted>
          <br />
          Visible Brand Actions™
          <br />
          is achieved through a sum of disciplines.
          <br />
          <br />
          Brand Strategy
          <br />
          Experiential Activation
          <br />
          Public Relations
          <br />
          360° Campaigns
        </Highlighted>
      </Container>
    </Layout>
  )
}

export default VisibleBrandActions
